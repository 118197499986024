import React from 'react'

import MenuButton from './menu-button'
import { Wrapper, ContactButton, Logo } from './index.styled'

const Header = ({ className, id = '', layout = '', isHome = false }) => {
  return (
    <Wrapper
      className={[className, layout, isHome ? 'homePage' : ''].join(' ')}
      id={id ? id : 'header'}
    >
      <Logo to="/" />
      <ContactButton to={'/contact-upper-east-side-apartments/'}>Contact us</ContactButton>
      <MenuButton layout={layout} />
    </Wrapper>
  )
}

export default Header
