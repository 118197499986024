import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

import MenuIcon from 'icons/menu.svg'

export const MenuButtonIcon = styled.button`
  -webkit-mask-image: url(${MenuIcon});
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: auto 100%;

  mask-image: url(${MenuIcon});
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: auto 100%;
  background: var(--white);
  border: none;

  height: ${calculateResponsiveSize(22)};
  width: ${calculateResponsiveSize(40)};
  cursor: pointer;
  &.transparent {
    background: var(--black);
  }
  &.tan {
    background: var(--black);
  }

  ${mediaMax('mobile')} {
    margin-left: auto;
    height: 17px;
    width: 40px;
    background: var(--white);
    &.transparent {
      background: var(--white);
    }
  }
`
