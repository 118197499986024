import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

import HeaderLogo from 'icons/header-logo.svg'
import HeaderLogoGray from 'icons/header-logo-gray.svg'

export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(367)};
  height: ${calculateResponsiveSize(23)};
  background: url(${HeaderLogo}) no-repeat;
  background-size: contain !important;
`
export const ContactButton = styled((props) => <Link {...props} />)`
  margin-left: auto;
  margin-right: ${calculateResponsiveSize(24)};

  color: var(--white);
  text-align: center;
  font-family: 'Gill Sans 600', Arial, Helvetica, sans-serif;
  font-size: ${calculateResponsiveSize(12)};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: ${calculateResponsiveSize(1.68)};
  text-transform: uppercase;
  text-decoration: none;
`
export const Wrapper = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 100;
  height: ${calculateResponsiveSize(108)};
  background: var(--green);
  color: var(--white);
  font-family: 'Gill Sans 600', Arial, Helvetica, sans-serif;
  padding: 0 ${calculateResponsiveSize(40)};

  &.homePage {
    position: absolute;
  }
  &.tan {
    background: var(--tan);
    ${Logo} {
      background: url(${HeaderLogoGray}) no-repeat;
      width: ${calculateResponsiveSize(367)};
      height: ${calculateResponsiveSize(23)};
    }
    ${ContactButton} {
      color: var(--black);
    }
  }
  &.transparent {
    background: transparent;
    ${Logo} {
      background: url(${HeaderLogoGray}) no-repeat;
      width: ${calculateResponsiveSize(367)};
      height: ${calculateResponsiveSize(23)};
    }
    ${ContactButton} {
      color: var(--black);
    }
  }
  &.non-header,
  &#hide {
    display: none;
  }

  ${mediaMax('mobile')} {
    position: fixed;
    padding: 0 16px;
    &.homePage {
      position: fixed;
    }
    height: 61px;
    background: var(--green);

    ${Logo} {
      background: url(${HeaderLogo}) no-repeat;
      width: 219px;
      height: 13px;
    }

    ${ContactButton} {
      display: none;
    }
    &.transparent {
      background: var(--green);
      ${Logo} {
        background: url(${HeaderLogo}) no-repeat;
      }
    }
    &.tan,
    &.transparent {
      ${Logo} {
        width: 219px;
        height: 13px;
      }
    }
  }
`
