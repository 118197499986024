import styled from 'styled-components'
import { mediaMax } from 'helpers/mediaQueries'

export const RotateScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 0;
  height: 0;
  background: var(--green);
  overflow: hidden;
  visibility: hidden;
  z-index: 1000;
  ${mediaMax('mobileLandscape')} {
    width: 100%;
    height: 100%;
    visibility: visible;
  }
  ${mediaMax('phoneXL')} {
    width: 0;
    height: 0;
    visibility: hidden;
  }
`

export const ImgWrap = styled.div`
  width: 74px;
  margin-bottom: 24px;
`

export const Title = styled.h1`
  max-width: 325px;
  margin: 0;
  text-align: center;
  font-family: 'Gill Sans 300', Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: var(--white);
  font-weight: 300;
  line-height: normal;
  letter-spacing: 2.16px;
  text-transform: uppercase;
`
