import React from 'react'
import CookieConsent from 'react-cookie-consent'

import { LinkItem, Content, Wrap } from './index.styled'

const CookiesBanner = () => {
  return (
    <Wrap>
      <CookieConsent
        location={'bottom'}
        buttonText={'Accept'}
        disableStyles={true}
        containerClasses="cookies-banner"
        buttonClasses="cookies-banner-accept-btn"
        disableButtonStyles={true}
        buttonWrapperClasses={'cookies-banner-btns'}
        contentClasses={'cookies-banner-content'}
        expires={150}
        //debug={true}
      >
        <Content>
          This website uses cookies to ensure you get the best experience. By
          using this website you agree to our{' '}
          <LinkItem href="https://www.cookiesandyou.com" target="_blank">Cookies Policy</LinkItem>.
        </Content>
      </CookieConsent>
    </Wrap>
  )
}
export default CookiesBanner
