import React, { useState, useEffect } from 'react'

import { FullScreenImageSection, LogoWrap } from './index.styled'
import LogoMain from '../../../images/logo-main.svg'

const MainIntro = ({ setIsFirstLoad = () => {} }) => {
  const [isFinish, seIsFinish] = useState(false)

  useEffect(() => {
    document.body.classList.add('hidden')
    setTimeout(() => {
      seIsFinish(true)
    }, 3000)
    setTimeout(() => {
      document.body.classList.remove('hidden')
      setIsFirstLoad(false)
    }, 4000)
  }, [setIsFirstLoad])

  return (
    <FullScreenImageSection className={isFinish ? 'hide' : ''}>
      <LogoWrap>
        <img alt="" src={LogoMain} />
      </LogoWrap>
    </FullScreenImageSection>
  )
}

export default MainIntro
