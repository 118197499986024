import React from 'react'
import { RotateScreenWrapper, ImgWrap, Title } from './index.styled'
import ImgRotateScreen from '../../images/img-rotate-screen.svg'

const RotateScreen = () => {
  return (
    <RotateScreenWrapper>
      <ImgWrap>
        <img alt="" src={ImgRotateScreen} />
      </ImgWrap>
      <Title>Please rotate your screen for best viewing experience</Title>
    </RotateScreenWrapper>
  )
}

export default RotateScreen
