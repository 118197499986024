import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import FooterLogo from 'icons/footer-logo.svg'
import FooterLogoWhite from 'icons/footer-logo-white.svg'

import fbIcon from 'icons/fb.svg'
import instagramIcon from 'icons/instagram.svg'

export const Text = css`
  font-family: 'Gill Sans 400', Arial, Helvetica, sans-serif;
  font-size: ${calculateResponsiveSize(14)};
  font-style: normal;
  font-weight: 400;
  line-height: ${calculateResponsiveSize(20)};
  color: var(--black);
  ${mediaMax('mobile')} {
    font-size: 12px;
    line-height: 18px;
  }
`
export const Logo = styled.div`
  width: ${calculateResponsiveSize(245)};
  height: ${calculateResponsiveSize(38)};
  background: url(${FooterLogo}) no-repeat;
  background-size: contain !important;

  ${mediaMax('mobile')} {
    width: 323px;
    height: 50px;
    order: 2;
    margin-top: auto;
  }
`
export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(80)};

  ${mediaMax('mobile')} {
    flex-direction: column;
    gap: 5px;
    height: 100%;
    text-align: center;
  }
`
export const Address = styled.div`
  ${Text}
  white-space: break-spaces;
`
export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  & a {
    ${Text}
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  ${mediaMax('mobile')} {
    flex-direction: column-reverse;
  }
`

export const Icon = styled.span`
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: contain;

  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;

  width: ${calculateResponsiveSize(18)};
  height: ${calculateResponsiveSize(18)};
  display: block;
  cursor: pointer;
  background: var(--black);
  &.fb {
    mask-image: url(${fbIcon});
    -webkit-mask-image: url(${fbIcon});
  }

  &.instagram {
    mask-image: url(${instagramIcon});
    -webkit-mask-image: url(${instagramIcon});
  }
  ${mediaMax('mobile')} {
    width: 18px;
    height: 18px;
  }
`

export const SocialWrap = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(24)};
  margin-left: auto;
  &.header {
    margin-bottom: ${calculateResponsiveSize(18)};
    ${Icon} {
      width: ${calculateResponsiveSize(13.5)};
      height: ${calculateResponsiveSize(13.5)};
      background: var(--gray);
    }
  }
  ${mediaMax('mobile')} {
    gap: 24px;
    margin-left: 0;

    &.header {
      margin-bottom: 18px;
      gap: 12px;

      ${Icon} {
        width: 13.5px;
        height: 13.5px;
      }
    }
  }
`

export const Wrapper = styled.footer`
  height: ${calculateResponsiveSize(100)};
  border-top: 1px solid var(--black);
  background: var(--gray);
  display: flex;
  align-items: center;
  padding: 0 ${calculateResponsiveSize(40)};
  color: var(--black);
  &.tan {
    background: var(--tan);
  }
  &.green-tan {
    background: var(--tan);
  }
  &.gray2 {
    background: var(--gray2);
  }
  &.green {
    background: var(--green);
    border-top: 1px solid var(--gray);
    ${Address} {
      color: var(--white);
    }
    ${Contact} {
      a {
        color: var(--white);
      }
    }
    ${Logo} {
      background: url(${FooterLogoWhite}) no-repeat;
    }
    ${Icon} {
      background: var(--white);
    }
  }
  &.white {
    background: var(--white);
  }

  ${mediaMax('mobile')} {
    height: 298px;
    padding: 32px 0 40px;
    flex-direction: column-reverse;
    gap: 38px;
  }
`
