import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

export const Wrap = styled.div`
  .cookies-banner {
    position: fixed;
    background: var(--tan);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    color: var(--black);
    width: 100%;
    height: ${calculateResponsiveSize(74)};
    padding: 0 ${calculateResponsiveSize(32)};
    font-family: 'Gill Sans 400', Arial, Helvetica, sans-serif;
    font-size: ${calculateResponsiveSize(14)};
    line-height: ${calculateResponsiveSize(16)};
  }
  .cookies-banner-accept-btn {
    font-family: 'Gill Sans 400', Arial, Helvetica, sans-serif;
    font-size: ${calculateResponsiveSize(14)};
    line-height: ${calculateResponsiveSize(16)};
    letter-spacing: 0.2em;
    background: none;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
  }

  ${mediaMax('mobile')} {
    .cookies-banner {
      height: 119px;
      padding: 16px 39px;
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;
      line-height: 16px;
    }
    .cookies-banner-accept-btn {
      font-size: 14px;
      line-height: 16px;
    }
    .cookies-banner-btns {
      margin-left: auto;
    }
  }
`
export const Content = styled.div``

export const LinkItem = styled.a`
  color: var(--black);

  font-family: 'Gill Sans 400', Arial, Helvetica, sans-serif;
  font-size: ${calculateResponsiveSize(14)};
  line-height: ${calculateResponsiveSize(16)};
  line-height: 120%;
  text-decoration-line: underline;
  background: none;
  border: none;
  cursor: pointer;

  ${mediaMax('mobile')} {
    font-size: 14px;
    line-height: 16px;
  }
`
