import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

export const LogoWrap = styled.div`
  animation: 3s ease 0s normal forwards 1 fadein;
  @keyframes fadein {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    33% {
      opacity: 1;
    }
    66% {
      transform: translateY(0px);
    }
  }
  width: ${calculateResponsiveSize(556)};
  ${mediaMax('mobile')} {
    width: 100%;
    max-width: 332px;
  }
  & > img {
    width: 100%;
  }
`

export const FullScreenImageSection = styled.section`
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 320px;
  height: 100dvh;
  z-index: 200;
  background: var(--gray);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${calculateResponsiveSize(32)};
  ${mediaMax('mobile')} {
    padding: 0 32px;
  }
  transition: 1s;
  overflow: hidden;
  opacity: 1;

  &.hide {
    opacity: 0;
  }
`
