import { createGlobalStyle } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

const GlobalStyle = createGlobalStyle`
  :root {
    --green: #3D5131;
    --black: #3F3C3A;
    --gray: #E7E7E8;
    --gray2: #D9D9D9;
    --white: #F3F2F2;
    --tan: #BCBAB7;
  } 
  li {
    list-style: none;
  }
  * {
    margin: 0;
    padding: 0;
    font-family: inherit;
    box-sizing: border-box;
  }
  body {
    width: 100%;
    height: 100%;
    font-family: 'Gill Sans 300', Arial, Helvetica, sans-serif;
    font-size: ${calculateResponsiveSize(18)};
    color: var(--black);
    line-height: 1.25;
    background: var(--gray);
    min-width: 320px;
    overflow-y: scroll;
  }
  body.hidden {
    height: 100vh !important;
  }
  body.hidden .home-wrapper {
    height: 100vh;
    overflow: hidden;
  }
  @keyframes mouse-over {
    0% {
      background-size: 0% 100%;
      background-position: 0% 100%;
    }
    100% {
      background-size: 100% 100%;
      background-position: 0% 100%;
    }
  }
  @keyframes mouse-out {
    0% {
      background-size: 100% 100%;
      background-position: 100% 0%;
    }
    100% {
      background-size: 0% 100%;
      background-position: 100% 0%;
    }
  }
  .gallery-swiper.swiper{
    height: 100%;
  }
  .gallery-swiper .swiper-wrapper{
    height: calc(100% - 40px);
  }
  .gallery-swiper .swiper-slide{
    display: flex;
  }

  .portal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 103;
    display: flex;
    justify-content: center;
  }
  .swiper-pagination{
    display: flex;
    gap: 11px;
    justify-content: center;
    margin-top: 10px;
  }
  .swiper-pagination-bullet,
  .swiper-pagination-bullet-active{
    width: 5px;
    height: 5px;
    background: #000;
    display: block;
    opacity: 0.3;
    cursor: pointer;
    border-radius: 50%;
  }
  .swiper-pagination-bullet-active{
    opacity: 1;
  }

  .swiper-fade .swiper-slide {
    pointer-events: none;
    transition-property: opacity;
    opacity: 0;
  } 

  .swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .mapboxgl-canvas-container{
    ${mediaMax('mobile')} {
      width: 100vw;
    }
  }

  .fadeIn {
    transform: translateY(50px);
    opacity: 0;
    transition: all 1s ease;
    &.visible {
      transform: translateY(0);
      opacity: 1;
    }
  }

  ${mediaMax('mobileLandscape')} {
    body {
      background: var(--green);
    }
  }
  ${mediaMax('phoneXL')} {
    body {
      background: var(--gray);
    }
  }
`

export default GlobalStyle
