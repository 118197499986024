import React from 'react'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'

import { Container, Left, Right } from './index.styled'

const NavigationPrevNext = ({ layout }) => {
  const {
    menuContent: {
      content: { primary, secondary },
    },
  } = useStaticQuery(graphql`
    query MenuQuery {
      menuContent {
        content {
          primary {
            text
            url
            submenu {
              text
              url
            }
          }
          secondary {
            text
            url
          }
        }
      }
    }
  `)
  const links = [...primary, ...secondary]
  const location = useLocation()

  const index = links.findIndex(
    ({ url }) =>
      location.pathname === url ||
      (url !== '/' && location.pathname.startsWith(url)),
  )

  if (index === -1 || location.pathname === '/contact-upper-east-side-apartments/') return null

  const prev = index === 0 ? null : links[index - 1]
  const next = index + 1 >= links.length ? links[0] : links[index + 1]

  return (
    <Container
      className={[layout, location.pathname === '/team/' && 'team'].join(' ')}
    >
      {prev ? <Left to={prev.url}>{prev.text}</Left> : <div />}
      <Right to={next.url}>{next.text}</Right>
    </Container>
  )
}

export default NavigationPrevNext
