import React from 'react'
import { Link } from 'gatsby-link'
import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

import ArrowIcon from 'icons/leftArrow.svg'

export const Item = css`
  font-family: 'Treadwell Custom 400', 'Gill Sans 300', Arial, Helvetica, sans-serif;
  font-size: ${calculateResponsiveSize(22)};
  line-height: ${calculateResponsiveSize(24)};

  letter-spacing: 0.2em;

  color: var(--black);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(24)};
  text-transform: uppercase;
  transition: 0.5s;

  &:before {
    content: '';
    background: var(--black);
    -webkit-mask-position: center ${calculateResponsiveSize(-1)};
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    -webkit-mask-image: url(${ArrowIcon});

    mask-position: center ${calculateResponsiveSize(-1)};
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-image: url(${ArrowIcon});

    width: ${calculateResponsiveSize(32)};
    height: ${calculateResponsiveSize(12)};
    display: block;
    transform: rotate(0deg);
  }
  &:hover {
    opacity: 0.5;
  }
  ${mediaMax('mobile')} {
    font-size: 18px;
    line-height: normal;
    letter-spacing: 3.6px;
    gap: 8px;

    &:before {
      -webkit-mask-position: center -1px !important;
      mask-position: center -1px !important;
      width: 32px;
      height: 12px;
    }
    &:hover {
      opacity: 1;
    }
  }
`
export const Left = styled((props) => <Link {...props} />)`
  ${Item}
`
export const Right = styled((props) => <Link {...props} />)`
  ${Item}
  flex-direction: row-reverse;
  &:before {
    transform: rotate(-180deg);
    -webkit-mask-position: center bottom ${calculateResponsiveSize(-1)};
    mask-position: center bottom ${calculateResponsiveSize(-1)};
  }
  ${mediaMax('mobile')} {
    &:before {
      -webkit-mask-position: center bottom -1px !important;
      mask-position: center bottom -1px !important;
    }
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -2px;
  padding: ${calculateResponsiveSize(28)} ${calculateResponsiveSize(131)} ${calculateResponsiveSize(20)};
  background: transparent;
  &.tan {
    background: var(--tan);
  }
  &.green {
    background: var(--green);
    ${Left} {
      color: var(--gray);
      &:before {
        background: var(--gray);
      }
    }
    ${Right} {
      color: var(--gray);
      &:before {
        background: var(--gray);
      }
    }
  }
  &.green-tan {
    background: var(--tan);
  }
  &.gray2 {
    background: var(--gray2);
  }
  &.white {
    background: var(--white);
  }
  &.team {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  ${mediaMax('mobile')} {
    padding: 64px 24px;
    flex-direction: column-reverse;
    align-items: center;
    gap: 16px;
    &.team {
      position: static;
    }
  }
`
