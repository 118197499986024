import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import CloseIcon from 'icons/arrow.svg'

export const MenuWrap = styled.div`
  height: 100%;
  position: fixed;
  background: var(--black);
  color: #fff;
  top: 0;
  right: 0;
  z-index: 20;
  padding: ${calculateResponsiveSize(40)} ${calculateResponsiveSize(48)};
  transform: translateX(100%);
  transition: 1s;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${mediaMax('mobile')} {
    width: 100%;
    padding: 5vh 4vh;
  }
`

export const Item = styled((props) => <Link {...props} />)`
  color: #fff;
  font-family: 'Gill Sans 400', Arial, Helvetica, sans-serif;
  font-size: ${calculateResponsiveSize(20)};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: ${calculateResponsiveSize(4)};
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  padding: 2px 0;
  border-bottom: 1px solid transparent;
  transition: 0.5s;
  &.secondary {
    font-size: ${calculateResponsiveSize(14)};
    letter-spacing: ${calculateResponsiveSize(2.8)};
  }
  &:hover {
    border-bottom: 1px solid white;
  }

  ${mediaMax('mobile')} {
    font-size: 20px;
    line-height: 23px;
    letter-spacing: ${calculateResponsiveSize(4)};
    letter-spacing: 0.2em;

    &.secondary {
      font-size: 14px;
      line-height: 16px;
    }
  }
`

export const Li = styled.li``
export const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 19;
  visibility: hidden;

  background: #000;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
`

export const Primary = styled.ul`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: ${calculateResponsiveSize(39)};

  margin-top: auto;
  ${mediaMax('mobile')} {
    gap: 4vh;
  }
`
export const Submenu = styled.ul`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: ${calculateResponsiveSize(18)};
  margin-top: ${calculateResponsiveSize(18)};
  width: 100%;
  ${mediaMax('mobile')} {
    margin-top: 16px;
  }
`

export const Secondary = styled.ul`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: ${calculateResponsiveSize(23)};

  margin-bottom: auto;
  margin-top: ${calculateResponsiveSize(63)};
  ${mediaMax('mobile')} {
    gap: 2vh;
    margin-top: 5vh;
  }
`

export const CloseButton = styled.button`
  -webkit-mask-image: url(${CloseIcon});
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: contain;

  mask-image: url(${CloseIcon});
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  background: var(--white);
  border: none;

  height: ${calculateResponsiveSize(22)};
  width: ${calculateResponsiveSize(31)};
  cursor: pointer;
  ${mediaMax('mobile')} {
    height: 20px;
    width: 28px;
    flex: 0 0 auto;
  }
`
export const Disclaimers = styled.a`
  font-family: 'Gill Sans 400', Arial, Helvetica, sans-serif;
  font-size: ${calculateResponsiveSize(12)};
  font-style: normal;
  font-weight: 400;
  line-height: ${calculateResponsiveSize(18)};
  margin-bottom: ${calculateResponsiveSize(8)};
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: 0.5s;
  &:hover {
    border-bottom: 1px solid white;
  }
  ${mediaMax('mobile')} {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0;
  }
`
export const Wrapper = styled.div`
  z-index: 101;
  position: relative;
  &.open {
    ${MenuWrap} {
      transform: translateX(0);
    }
    ${Overlay} {
      opacity: 0.3;
      visibility: visible;
    }
  }

  ${mediaMax('mobile')} {
    &.open {
      ${MenuWrap} {
        transform: translateX(0);
      }
      ${Overlay} {
        display: none;
      }
    }
  }
`
