import React from 'react'
import { MenuButtonIcon } from './index.styled'

const MenuButton = ({ layout = '' }) => {
  const onClick = () => {
    const menu = document.querySelector('#menu')
    menu.classList.toggle('open')
  }

  return <MenuButtonIcon onClick={onClick} className={layout} />
}

export default MenuButton
