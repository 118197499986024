import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { SocialWrap, Icon } from './index.styled'

const SocialLinks = ({ className }) => {
  const {
    dataJson: {
      footerInfo: { socialLinks },
    },
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          socialLinks {
            type
            link
          }
        }
      }
    }
  `)
  return (
    <SocialWrap className={className}>
      {socialLinks.map((l, i) => {
        return (
          <a key={`links-social-${i}`} href={l.link} target={'_blank'} rel='noreferrer'>
            <Icon className={l.type} />
          </a>
        )
      })}
    </SocialWrap>
  )
}

export default SocialLinks
