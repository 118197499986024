import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SocialLinks from './social'
import {
  Wrapper,
  Logo,
  Info,
  Address,
  Contact,
} from './index.styled'

const Footer = ({ layout }) => {
  const {
    dataJson: {
      footerInfo: { phone, email, address },
    },
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          phone
          email
          address
          socialLinks {
            type
            link
          }
          links {
            title
            link
          }
        }
      }
    }
  `)
  return (
    <Wrapper className={layout}>
      <Info>
        <Logo />
        <Address>{address}</Address>
        <Contact>
          <a href={`tel:${phone}`}>{phone}</a>
          <a href={`mailto:${email}`}>{email}</a>
        </Contact>
      </Info>
      <SocialLinks />
    </Wrapper>
  )
}

export default Footer
