import React, { useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SocialLinks from '../../footer/social'
import {
  Wrapper,
  MenuWrap,
  Item,
  Li,
  Overlay,
  Primary,
  Secondary,
  CloseButton,
  Disclaimers,
  Submenu,
} from './index.styled'
import Fairhousingnotice from '../../../static/Treadwell_fairhousingnotice.pdf'

const Menu = () => {
  const {
    menuContent: { content: links },
  } = useStaticQuery(graphql`
    query MenuQuery {
      menuContent {
        content {
          primary {
            text
            url
            submenu {
              text
              url
            }
          }
          secondary {
            text
            url
          }
        }
      }
    }
  `)
  const menuRef = useRef(null)
  const onClose = () => {
    menuRef.current.classList.remove('open')
  }
  console.dir(links.primary)
  return (
    <Wrapper id={'menu'} ref={menuRef}>
      <MenuWrap>
        <CloseButton onClick={onClose} />
        <Primary>
          {links.primary.map((item, index) => (
            <Li key={`primary-item-${index}`} onClick={onClose}>
              <Item to={item.url}>{item.text}</Item>
              {item.submenu ? (
                <Submenu>
                  {item.submenu.map((subItem, j) => (
                    <Li key={`primary-item-${index}-${j}`} onClick={onClose}>
                      <Item to={subItem.url} className={'secondary'}>
                        {subItem.text}
                      </Item>
                    </Li>
                  ))}
                </Submenu>
              ) : null}
            </Li>
          ))}
        </Primary>
        <Secondary>
          {links.secondary.map((item, index) => (
            <Li key={`secondary-item-${index}`} onClick={onClose}>
              <Item to={item.url} className={'secondary'}>
                {item.text}
              </Item>
            </Li>
          ))}
        </Secondary>
        <SocialLinks className={'header'} />
        <Disclaimers href={Fairhousingnotice} target="_blank">
          FAIR HOUSING NOTICE
        </Disclaimers>
        <Disclaimers href="/disclaimer">DISCLAIMERS</Disclaimers>
      </MenuWrap>
      <Overlay onClick={onClose} />
    </Wrapper>
  )
}

export default Menu
