import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import { gsap, ScrollSmoother, ScrollTrigger } from 'helpers/gsap'
import useWindowSize from 'helpers/useWindowSize'
import Header from 'components/header'
import Menu from '../header/menu'
import Footer from 'components/footer'
import NavigationPrevNext from '../navigation-prev-next'
import MainIntro from '../ui/main-intro'
import CookiesBanner from '../ui/cookies-banner'

import 'helpers/fonts.css'
import GlobalStyle from 'helpers/globalStyles'
import { LayoutWrapper, PageWrapper, ContentWrapper } from './index.styled'
import RotateScreen from '../rotate-screen'

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 1000 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <LayoutWrapper>{children}</LayoutWrapper>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

const Layout = (props) => {
  const {
    children,
    location,
    pageContext: { layout }, 
  } = props
  const refWrapper = React.useRef(null)
  const isHomePage = location.pathname === '/'
  const { isMobile, width } = useWindowSize()
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  let scrollerSmoother

  React.useLayoutEffect(() => {
    let gsapContext = gsap.context(() => {
      // const mediaQueryAnimation = gsap.matchMedia()
      // mediaQueryAnimation.add(`(min-width: 1200px)`, () => {
        scrollerSmoother = ScrollSmoother.create({
          content: '.home-content',
          wrapper: '.home-wrapper',
          smooth: 2,
          effects: true,
        })
      // })
      //pin
      gsap.utils.toArray('.pin').forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          pinSpacing: false,
          pin: true,
          start: 'bottom bottom',
          anticipatePin: 1,
        })
      })
      //fadeIn
      gsap.utils.toArray('.fadeIn').forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          start: 'top 90%',
          end: '+=1000%',
          toggleClass: {
            targets: container,
            className: 'visible',
            toggle: 'add',
          },
          once: true,
        })
      })
    }, refWrapper)

    return () => gsapContext.revert()
  }, [children])

  useEffect(() => {
    if (scrollerSmoother && location.hash) {
      setTimeout(() => {
        const height =
          document.querySelector('#header').clientHeight ||
          document.querySelector('#headerMob #menu').clientHeight ||
          0

        gsap.to(scrollerSmoother, {
          scrollTop: document.querySelector(`${location.hash}-section`)?.offsetTop - height,
          duration: -1,
        })
      }, 100)
    }
  }, [scrollerSmoother, location.hash])

  return (
    <>
      <RotateScreen/>
      <div className="home-wrapper" ref={refWrapper}>
        {!( width > 576 && width < 830) && isHomePage && isFirstLoad && (
          <MainIntro setIsFirstLoad={setIsFirstLoad} />
        )}
        <Header layout={layout} id={isHomePage && !isMobile ? 'hide' : ''} />
        <Menu />
        <div className="home-content">
          <PageWrapper>
            <GlobalStyle />
            <ContentWrapper>
              {!( width > 576 && width < 830) && <LayoutContent location={location}>{children}</LayoutContent> }
            </ContentWrapper>
            <NavigationPrevNext layout={layout} />
          </PageWrapper>
          {!( width > 576 && width < 830) && <Footer layout={layout} /> }
        </div>
        <CookiesBanner />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
